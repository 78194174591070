@import 'palette';
@import 'typo';

/*------------------------------------*\
    #Body
\*------------------------------------*/

$body-bg: $black;
$body-color: $white;

/*------------------------------------*\
    #Typography
\*------------------------------------*/

$fontFamily: 'Overpass', sans-serif;
$headings-font-family: 'Overpass', sans-serif;

$line-height-base: 1.6;
$font-size-base: 20px;
$h1-font-size: 76px;
$h2-font-size: 60px;
$h3-font-size: 46px;
$h4-font-size: 34px;
$h5-font-size: 25px;

$lead-font-size: $h1-font-size;
$lead-font-weight: 600;
$lead-font-line-height: 1.2;
$lead-font-letter-spacing: 0;

$headings-line-height-base: 1.4;
$headings-line-height: $headings-line-height-base;
$headings-margin-bottom: 1.5rem;
$enable-responsive-font-sizes: true;

/*------------------------------------*\
    #Buttons
\*------------------------------------*/

$global-border-radius: 0.3rem;
$btn-border-radius: 1rem 0 1rem 0;
$btn-border-radius-sm: $btn-border-radius;

$btn-font-weight: 500;
$btn-font-transform: initial;

$btn-padding-y: 0.8rem;
$btn-padding-x: 4rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;

$btn-outline-primary-color: $white;
$link-color: map-get($theme-colors, 'primary') !important;

/*------------------------------------*\
    #Container
\*------------------------------------*/

$container-padding-x: 2rem;

$container-max-widths: (
    sm: 33.75rem,
    //540px
    md: 45rem,
    //720px
    lg: 50rem,
    //800px
    xl: 66.875rem,
    //1070px,
    xxl: 73.5rem,
);

/*------------------------------------*\
    #Spacing
\*------------------------------------*/

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5,
    7: $spacer * 8,
);

/*------------------------------------*\
    #Loader
\*------------------------------------*/

$loader-bg-color: $black;
$loader-logo-color: $white;

/*------------------------------------*\
    #Navbar & Footer
\*------------------------------------*/

$navbar-nav-link-padding-x: 1.5rem;
$navbar-padding-x: 4rem;
$navbar-padding-y: 2rem;
$navbar-collapsed-padding: 2rem 6rem;

$navbar-bg-color: $black;
$navbar-collapsed-bg-color: $black;
$navbar-secondary-collapsed-bg-color: $white;

$navbar-font-color: $white;
$navbar-active-color: map-get($theme-colors, 'primary');
$navbar-font-hover-color: map-get($theme-colors, 'primary');
$navbar-secondary-font-color: $white;
$navbar-collapsed-font-color: $white;
$navbar-secondary-collapsed-font-color: $white;

$navbar-svg-color: $white;
$navbar-collapsed-svg-color: $black;
$navbar-secondary-collapsed-svg-color: $white;

$navbar-dropdouwn-bg-color: $white;
$navbar-dropdouwn-item-color: $black;
$navbar-selected-bg-color: transparent;
$navbar-selected-item-color: map-get($theme-colors, 'primary');

$mobile-navbar-bg-color: $black;
$mobile-navbar-svg-color: $white;
$mobile-navbar-font-color: $white;

$footer-bg-color: $black;
$footer-font-color: $white;
$footer-bg-copyright: #000;

/*------------------------------------*\
    #Header Images
\*------------------------------------*/

$image-section-height: 100vh;
$image-section-md-height: 40rem;
$image-section-sm-height: 30rem;
$image-section-overlay: rgba(0, 0, 0, 0);

/*------------------------------------*\
    #Cards
\*------------------------------------*/

$card-border: 1px solid $gray-600;
$card-border-radius: 0.5rem;

$card-bg-color: $white;
$card-border-color: $white;

$card-dark-bg-color: rgb(0 0 0 / 40%);
$card-dark-border: 1px solid $white;

$card-avatar-color: $gray-600;

$card-padding: 2rem;

/*------------------------------------*\
    #Modal
\*------------------------------------*/

$modal-fade-transform: scale(1);
$modal-content-bg: #161616;
$modal-inner-padding: 2rem;

/*------------------------------------*\
    #Accordion
\*------------------------------------*/

$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-border-radius: 0;
$accordion-border-width: 0;
$accordion-button-focus-box-shadow: unset;
$accordion-button-padding-y: 1rem;
$accordion-button-padding-x: 0;
